import React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const globalStyles = css`
  html {
    box-sizing: border-box;
    font-size: 14px;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
  }

  a {
    font-weight: 500;
    color: #6B818C;
    text-decoration: none;
    background-image: none;
    transition: color .4s;
    &:hover {
      color: #35605A;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  button {
    font-family: inherit;
    user-select: none;
  }

  ::selection {
    background-color: #f4bda5;
  }
`;

const Layout = ({ children }) => (
  <Wrapper>
    <Global
      styles={globalStyles}
    />
    {children}
  </Wrapper>
);

export default Layout;

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  @media (min-width: 600px){
    padding-top: 2em;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
