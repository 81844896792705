import React from 'react';
import styled from '@emotion/styled';
import Profile from '../images/profile.jpg';

const Header = () => (
  <StyledHeader>
    <ImageWrapper>
      <Image src={Profile} alt="Cristiano Pio" />
    </ImageWrapper>
    <h1>Cristiano Pio (he/him)</h1>
  </StyledHeader>
);

export default Header;

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
`;

const Image = styled.img`
  width: auto;
`;
