/* eslint-disable max-len */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';

const useSiteTitle = () => {
  const data = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );
  return data.site.siteMetadata.title;
};

const Home = () => {
  const title = useSiteTitle();
  return (
    <Layout>
      <SEO title={title} />
      <Header />
      <Wrapper>
        <h2>
          Hi, I&apos;m a Senior Product Manager based in Vancouver, BC.
        </h2>
        <p>
          I&apos;ve had an exciting journey across Latin America, Europe, and North America, taking on various positions as a Product Manager. I started off my career as an academic researcher in Denmark, but decided to join a startup instead and have been in tech for the past 10 years.
        </p>
        <p>
          I have recently joined
          {' '}
          <a href="https://loopio.com/" target="_blank" rel="noopener noreferrer">Loopio</a>
          &apos;s product team, a Toronto-based software company that streamlines the RFP response process. Its software helps teams respond faster with higher quality and win more business.
        </p>
        <p>
          Prior to my current role, I spent 4 and a half years at
          {' '}
          <a href="https://www.hootsuite.com/" target="_blank" rel="noopener noreferrer">Hootsuite</a>
          {' '}
          as a Senior Product Manager for their Engagement portfolio.
          {' '}
          Before that, I had the pleasure of leading a small and talented product team at
          {' '}
          <a href="https://www.sympla.com.br/" target="_blank" rel="noopener noreferrer">Sympla</a>
          ,
          the largest online ticket and event platform in Latin America - similar to
          {' '}
          <a href="https://www.eventbrite.ca/" target="_blank" rel="noopener noreferrer">Eventbrite.</a>
          {' '}
          I have also been part of product teams at
          {' '}
          <a href="https://www.falcon.io/" target="_blank" rel="noopener noreferrer">Falcon.io</a>
          {' '}
          and
          {' '}
          <a href="https://tradeshift.com/" target="_blank" rel="noopener noreferrer">Tradeshift</a>
          {' '}
          while living in Denmark.
        </p>
        <p>
          Outside of work, I enjoy mentoring aspiring PMs at the
          {' '}
          <a href="https://www.ubc.ca/" target="_blank" rel="noopener noreferrer">University of British Columbia</a>
          {' '}
          and contributing to the product community.

          You can reach me at
          {' '}
          <a href="mailto:cristiano.pio@loopio.com" target="_blank" rel="noopener noreferrer">cristiano.pio@loopio.com</a>
          .
        </p>
      </Wrapper>
      <Footer />
    </Layout>
  );
};

export default Home;

const Wrapper = styled.div`
  margin-bottom: 1.5em;
`;
