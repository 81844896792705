import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedinIn, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faLinkedinIn, faInstagram, faTwitter);

const SocialItem = ({ item }) => (
  <Item>
    <a href={item.url} target="_blank" rel="noopener noreferrer">
      {' '}
      <FontAwesomeIcon icon={item.icon} size="2x" />
    </a>
  </Item>
);

SocialItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

const socialList = [
  {
    name: 'Linkedin',
    icon: ['fab', 'linkedin-in'],
    url: 'https://www.linkedin.com/in/cristianopio/',
  },
  {
    name: 'Twitter',
    icon: ['fab', 'twitter'],
    url: 'https://twitter.com/orsipio',
  },
  {
    name: 'Instagram',
    icon: ['fab', 'instagram'],
    url: 'https://www.instagram.com/cristianorsi/',
  },
];

const Footer = () => (
  <StyledFooter>
    <SocialList>
      {socialList.map((item) => <SocialItem key={item.name} item={item} />)}
    </SocialList>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.footer`
  padding-top: 1em;
`;

const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const Item = styled.li`
  padding: 0 1em 0 0;

`;
